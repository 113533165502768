import axios, { AxiosInstance } from "axios";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import useForm from "react-hook-form";
import { isEmail, ReactMultiEmail } from "react-multi-email";

interface IProps {
  open: boolean;
}

interface IFormData {
  email: string;
  firstname: string | null;
  lastname: string | null;
  type: string;
  operation?: string;
}

interface IMultiEmail {
  [emails: string]: string;
}

const apiUrl = `${process.env.GATSBY_API_URL}/marketing_leads`;

const client: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const SharingEmail: React.FC<IProps> = ({ open = false }) => {
  const [shareEmails, setShareEmails] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(open);
  const [successState, setSuccessState] = useState<boolean>(false);
  const { handleSubmit, register } = useForm<IMultiEmail>();

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  const sendDataToApi = (val: IFormData) => {
    client.post(apiUrl, val);
  };

  const onClickModalTrigger = (state: boolean) => {
    setOpenModal(state);
  };

  const onSubmitSharingForm = (values: IMultiEmail) => {
    const emailsArr = values.emails.split(",");
    if (emailsArr[0].length > 0) {
      emailsArr.forEach((el: string) => {
        if (el.length) {
          sendDataToApi({ email: el, firstname: "", lastname: "", type: "partage", operation: "fifty_startups" });
        }
      });
      showSuccessState();
    }
  };

  const showSuccessState = () => {
    setSuccessState(true);
  };

  return (
    <div className="share-title-modal-container">
      <h2
        className="title share-title-cta shadow-word"
        attr-text-shadow="Partager" onClick={() => onClickModalTrigger(true)}
      >
        Partager
        </h2>
      <div className={cx("modal-container", { open: openModal })}>
        <div className="modal">
          <span className="close" onClick={() => onClickModalTrigger(false)}></span>
          <span className="title modal-title">Partager</span>
          <p className="text modal-text">Pour partager ce contenu à vos proches, rien de plus simple.
          Indiquez leurs emails et cliquez sur partager. On s'occupe du reste :</p>
          <form className="sharing-form" onSubmit={handleSubmit(onSubmitSharingForm)}>
            <input type="hidden" name="emails" ref={register} value={shareEmails} />
            <ReactMultiEmail
              placeholder="Ajoutez les emails de vos amis"
              emails={shareEmails}
              ref={register}
              onChange={(_emails: string[]) => {
                setShareEmails(_emails);
              }}
              validateEmail={(email) => {
                return isEmail(email);
              }}
              getLabel={(
                email: string,
                index: number,
                removeEmail: (index: number) => void,
              ) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                </span>
                  </div>
                );
              }}
            />
            <div className="share-submit-container">
              <button
                disabled={successState}
                className={cx("btn btn-primary email-share-btn", { success: successState })}
              >
                <span className="share-wording">Partager</span>
                <span className="success-wording">C'est bon !</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SharingEmail;

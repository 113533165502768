import anime from "animejs";
import axios, { AxiosInstance } from "axios";
import cx from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useForm from "react-hook-form";
import LinkedInTag from "react-linkedin-insight";
import * as yup from "yup";

import Image from "../components/image";
import SEO from "../components/seo";
import SharingEmail from "../components/sharingEmail";

interface IFormData {
  email: string;
  firstname: string;
  lastname: string;
  type: string;
  operation?: string;
}

const fieldTrans: { [key: string]: string } = {
  email: "Email",
  firstname: "Prénom",
  lastname: "Nom",
};

const linkToPdf = process.env.GATSBY_PDF_LINK;
const apiUrl = `${process.env.GATSBY_API_URL}/marketing_leads`;

yup.setLocale({
  mixed: {
    required: ({ path }: any) => `Le champ ${fieldTrans[path]} est obligatoire.`,
  },
  string: {
    email: ({ value }: any) => `L'email "${value}" n'est pas un email valide.`,
  },
});

const VallidationSchema = yup.object().shape({
  email: yup.string().email().required(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
});

const client: AxiosInstance = axios.create();

const IndexPage: React.FC<{ location: Location }> = ({ location }: { location: Location }) => {
  const [dirtyIds, setDirtyIds] = useState<string[]>([]);
  const [invalidIds, setInvalidIds] = useState<string[]>([]);
  const [hasValueIds, setHasValueIds] = useState<string[]>([]);
  const [focusedInput, setFocusedInput] = useState<string>("");
  const [apiResponse, setApiResponse] = useState<string>("");
  const [animateLoader, setAnimateLoader] = useState<boolean>(false);
  const [successLoader, setSuccessLoader] = useState<boolean>(false);
  const [showDownloadCta, setShowDownloadCta] = useState<boolean>(false);
  const [failLoader, setFailLoader] = useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const checkCrossContainer = useRef(null);
  const btnSubmitContainer = useRef(null);
  const btnDownload = useRef(null);
  const loader = useRef(null);
  const pdfCta = useCallback((node) => {
    if (node !== null) {
      triggerPdfDownload(node);
    }
  }, []);

  const { handleSubmit, register, triggerValidation, errors } = useForm<IFormData>({
    validationSchema: VallidationSchema,
  });

  useEffect(() => {
    const linkedinPartnerId = String(process.env.REACT_APP_LINKEDIN_PARTNER_ID);
    LinkedInTag.init(linkedinPartnerId);
  }, []);

  useEffect(() => {
    switch (apiResponse) {
      case "success":
        showSuccessState();
        setOpenShareModal(true);
        break;
      case "fail":
        showFailState();
        break;
    }
  }, [apiResponse]);

  const sendDataToApi = (val: IFormData) => {
    client.post(apiUrl, val)
      .then(() =>
        setApiResponse("success"),
      )
      .catch(() =>
        setApiResponse("fail"),
      )
      ;
  };
  const onSubmitForm = (values: IFormData) => {
    setDisableBtn(true);
    setApiResponse("");
    showSubmitingState(() =>
      sendDataToApi({ ...values, operation: "fifty_startups" }),
    );
  };

  const showSubmitingState = (callback: () => void) => {
    setSuccessLoader(false);
    setFailLoader(false);
    const tl = anime.timeline({
      complete: () => {
        setAnimateLoader(true);
        if (typeof callback === "function") {
          callback();
        }
      },
      duration: 200,
      easing: "cubicBezier(1,0,.27,.97)",
    });

    tl
      .add({
        easing: "linear",
        opacity: 0,
        targets: btnDownload.current,
      })
      .add({
        duration: 300,
        targets: btnSubmitContainer.current,
        width: 34,
      })
      .add({
        duration: 1,
        opacity: 1,
        targets: loader.current,
      })
      .add({
        boxShadow: "inset 0 0 0 4px #fff",
        duration: 1,
        targets: btnSubmitContainer.current,
      })
      ;
  };

  const showSuccessState = () => {
    setSuccessLoader(true);
    setShowDownloadCta(true);
    anime({
      targets: checkCrossContainer.current,
      translateY: ["-100%", "0%"],
    });
  };

  const showFailState = () => {
    setFailLoader(true);
    anime({
      targets: checkCrossContainer.current,
      translateY: ["100%", "0%"],
    });
  };

  const triggerPdfDownload = (node: HTMLElement) => {
    const tm = setTimeout(() => {
      node.click();
      clearTimeout(tm);
    }, 2000);
  };
  const onFocusInput = (e: any) => {
    setFocusedInput(e.target.id);
  };
  const onBlurInput = (e: any) => {
    const id = e.target.id;
    const indexDirty = dirtyIds.findIndex((eId: string) => eId === id);
    if (focusedInput === id) {
      setFocusedInput("");
    }
    if (indexDirty === -1) {
      setDirtyIds([...dirtyIds, id]);
    }

    checkInputValidity(e);
    triggerValidation({ name: id });
  };
  const onChangeInput = (e: any) => {
    const id = e.target.id;
    const val = e.target.value;
    const idsList = [...hasValueIds];
    const index = idsList.findIndex((eId: string) => eId === id);

    if (val) {
      if (index === -1) {
        setHasValueIds([...idsList, id]);
      }
    } else {
      if (index !== -1) {
        idsList.splice(index, 1);
        setHasValueIds(idsList);
      }
    }

    checkInputValidity(e);
  };
  const checkInputValidity = (e: any) => {
    const id = e.target.id;
    const idsList = [...invalidIds];
    const index = idsList.findIndex((eId: string) => eId === id);

    if (!e.target.checkValidity()) {
      if (index === -1) {
        setInvalidIds([...idsList, id]);
      }
    } else {
      if (index !== -1) {
        idsList.splice(index, 1);
        setInvalidIds(idsList);
      }
    }
  };
  const createInput = (id: string, classes = [], type = "text") => {
    const isNotEmpty = hasValueIds.some((e) => e === id);
    const isDirty = dirtyIds.some((e) => e === id);
    const isNotValid = invalidIds.some((e) => e === id) || !!errors[id];
    const isFocused = focusedInput === id;

    return (
      <>
        {errors && errors[id] && errors[id].message
          ? <span className="error">{errors[id].message}</span>
          : null
        }
        <input
          className={cx(classes, { isNotEmpty }, { isFocused }, { isDirty }, { isNotValid })}
          ref={register}
          type={type}
          name={id}
          id={id}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          onChange={onChangeInput}
        />
      </>
    );
  };

  return (
    <>
      <SEO
        meta={{
          "og:image": "https://elinoi-uploads.s3.eu-west-3.amazonaws.com/50-startups-social.png",
          "og:image:height": "648",
          "og:image:secure_url": "https://elinoi-uploads.s3.eu-west-3.amazonaws.com/50-startups-social.png",
          "og:image:width": "754",
          "og:url": location.href,
        }}
        title="Home"
      />
      <section className="page index-page">
        <div className="container index-container">
          <div className="side-container">
            <h2 className="index-title">
              <span className="block-word shadow-word" attr-text-shadow="50 startups">50 startups</span>
              où postuler <strong>en 2020</strong>
            </h2>

            <div className="intro-text">
              <p className="text">
                Beaucoup de startups méritent d’être (mieux) connues.
                Ce n’est pas un babyfoot qui vous aidera à vous épanouir professionnellement sur le long terme.
                Il existe des milliers de projets en plein développement qui valent la peine d’être rejoints.<br />
                Et on aimerait vous expliquer pourquoi. C’est pour toutes ces raisons et bien d’autres encore qu’on
                vous présente plus en détail 50 startups où postuler en 2020.
              </p>
              <p className="text">
                Pour les découvrir, rien de plus simple, il suffit juste de remplir ces 4 cases :
              </p>
            </div>

            <div className="form-container">
              <form className="form" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="form-row double">
                  <div className="form-group">
                    <div className="form-group-content">
                      {createInput("firstname")}
                      <label className="label" htmlFor="firstname">Prénom</label>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-content">
                      {createInput("lastname")}
                      <label className="label" htmlFor="lastname">Nom</label>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <div className="form-group-content">
                      {createInput("email", [], "email")}
                      <label className="label" htmlFor="email">Votre email</label>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <div className="form-group-content">
                      <label className="label-fix" htmlFor="type">Vous êtes ?</label>
                      <div className="select-container">
                        <select name="type" id="type" ref={register}>
                          <option value="talent">Un(e) talent</option>
                          <option value="entreprise">Une entreprise</option>
                        </select>
                        <svg className="chevron" viewBox="0 0 15 9.1">
                          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron"></use>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cgu-text-container">
                  <p className="cgu-text text">
                    En cliquant sur ’’Télécharger le PDF’’, vous acceptez nos CGU.
                    N’hésitez pas à <a href="https://elinoi.com/conditions-generales-utilisation"
                      target="_blank" rel="noreferrer noopener">les lire</a>,
même si pour résumer on protégera toujours votre vie privée.
                  </p>
                </div>
                <div className="form-submit-container">
                  <div className="submit-cta-container" ref={btnSubmitContainer}>
                    <button className="btn btn-primary" disabled={disableBtn} ref={btnDownload}>Télécharger</button>
                    <span
                      className={
                        cx(
                          "loader", { animate: animateLoader },
                          { success: successLoader }, { fail: failLoader },
                        )
                      }
                      ref={loader}>
                      <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <circle r="8" cx="10" cy="10" />
                      </svg>
                    </span>
                    <span className="check-cross-container" ref={checkCrossContainer}>
                      <svg className={cx("check", { show: successLoader })} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#check"></use>
                      </svg>
                      <svg className={cx("cross", { show: failLoader })} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#cross"></use>
                      </svg>
                    </span>
                  </div>
                  {
                    showDownloadCta
                      ? <div className="start-download">
                        si le téléchargement n'est pas automatique, <a href={linkToPdf} ref={pdfCta}
                          target="_blank" rel="noreferrer noopener" download="Les-50-statups-2020">
                          cliquez ici
                        </a>
                      </div>
                      : null
                  }
                </div>
              </form>
            </div>
          </div>
          <div className="side-container image-side-container">
            <div className="image-container">
              <Image />
            </div>
          </div>
        </div>
      </section>
      <SharingEmail open={openShareModal} />
    </>
  );
};

export default IndexPage;
